import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(
    private _http: HttpClient
  ) { }

  getStoreEventById(id) {
    return this._http
      .get<any>(`${environment.apiBaseUrl}/public/admin/store/publicgetStoreEventById?event_id=${id}`)
      .pipe(
        retry(1),
        map((response) => {
          console.log('[getStoreEventById] result : ', response);
          return response.data;
        }),
        catchError((error) => {
          console.log('[getStoreEventById] error : ', error);
          const message = error
            ? error.error.message
            : "Something Went Wrong, Please Try Again!";
          return throwError(() => message);
        })
      );
  }

  getAdminContentMaster(type) {
    return this._http
      .get<any>(`${environment.apiBaseUrl}/public/admin/getAdminContentMaster?type=${type}`)
      .pipe(
        retry(1),
        map((response) => {
          console.log('[getAdminContentMaster] result : ', response);
          return response.data;
        }),
        catchError((error) => {
          console.log('[getAdminContentMaster] error : ', error);
          const message = error
            ? error.error.message
            : "Something Went Wrong, Please Try Again!";
          return throwError(() => message);
        })
      );
  }

  addUserEventTracking(data) {
    return this._http
      .post<any>(`${environment.apiBaseUrl}/public/store/event/addUserEventTracking`, data)
      .pipe(
        retry(1),
        map((response) => {
          console.log('[addUserEventTracking] result : ', response);
          return response.data;
        }),
        catchError((error) => {
          console.log('[addUserEventTracking] error : ', error);
          const message = error
            ? error.error.message
            : "Something Went Wrong, Please Try Again!";
          return throwError(() => message);
        })
      );
  }

  updateUserEventTracking(data) {
    return this._http
      .post<any>(`${environment.apiBaseUrl}/public/store/event/updateUserEventTracking`, data)
      .pipe(
        retry(1),
        map((response) => {
          console.log('[updateUserEventTracking] result : ', response);
          return response.data;
        }),
        catchError((error) => {
          console.log('[updateUserEventTracking] error : ', error);
          const message = error
            ? error.error.message
            : "Something Went Wrong, Please Try Again!";
          return throwError(() => message);
        })
      );
  }

  sendContactUsEmail(data) {
    return this._http
      .post<any>(`${environment.apiBaseUrl}/public/user/sendContactUsEmail`, data)
      .pipe(
        retry(1),
        map((response) => {
          console.log('[sendContactUsEmail] result : ', response);
          return response.data;
        }),
        catchError((error) => {
          console.log('[sendContactUsEmail] error : ', error);
          const message = error
            ? error.error.message
            : "Something Went Wrong, Please Try Again!";
          return throwError(() => message);
        })
      );
  }

  inquiryMail(email) {
    return this._http
      .get<any>(`${environment.apiBaseUrl}/public/admin/inquiryMail?email=${email}`)
      .pipe(
        retry(1),
        map((response) => {
          console.log('[inquiryMail] result : ', response);
          return response.data;
        }),
        catchError((error) => {
          console.log('[inquiryMail] error : ', error);
          const message = error
            ? error.error.message
            : "Something Went Wrong, Please Try Again!";
          return throwError(() => message);
        })
      );
  }
}