<div class="container-fluid px-0">
    <div class="banner-wrapper d-flex">
        <div class="container">
            <div
                class="header-nav d-flex flex-row justify-content-between justify-content-lg-between align-items-center my-3 my-lg-5">
                <div class="col-sm-4 logo"><img src=".././assets/clique-logo.svg" alt="clique"></div>
                <div class="select-option col-sm-4 justify-content-end d-flex align-items-center gap-3">
                    <!-- <label class="text-nowrap">Engage as</label> -->
                    <button class="choose-category" (click)="toggleCategory()">{{
                        currentCategory === 'Customer' ? 'Clique for Business' : 'Home'
                        }}</button>
                </div>
            </div>
            <div class="banner-text-wrap">
                <div class="text-tap col-sm-5">

                    <div *ngIf="currentCategory === 'Customer'" class="custom-content">
                        <h3>Your One-Step Solution for Managing Rewards
                        </h3>
                        <p>Easily Track and Maximize all your Rewards, Cashbacks, Discounts and Exclusive deals from
                            your favourite stores—all in one easy-to-use app.</p>
                    </div>

                    <div *ngIf="currentCategory === 'Business'" class="custom-content">
                        <h3>Attract And Grow Your Loyal Customer Base</h3>
                        <p>Retain your customers by creating loyalty programs, giftcards and engage with your customers
                            like never before to drive repeat business.</p>
                    </div>

                    <div class="newsletter-container" *ngIf="currentCategory == 'Business'">
                        <form [formGroup]="createForm" (submit)="onSubmit()" class="form">
                            <div class="email-input-container">
                                <input type="email" placeholder="E-mail" formControlName="email" required />
                                <button type="submit">
                                    <span class="material-symbols-outlined">
                                        arrow_right_alt
                                    </span>
                                </button>
                            </div>
                            <span style="color: white !important;">Request For Demo</span>
                        </form>
                    </div>

                </div>
                <div class="banner-img col-sm-7">
                    <div class="frame-section" *ngIf="currentCategory === 'Customer'">
                        <div class="image-container">
                            <img src=".././assets/frame_1707479811-1.webp" alt="clique" height="700" width="600"
                                class="d-none d-lg-flex">

                            <!-- Play Store Area -->
                            <a href="http://play.google.com/store/apps/details?id=com.getclique.app" target="_blank">
                                <div class="clickable-area play-store"></div>
                            </a>

                            <!-- App Store Area -->
                            <a href="http://apps.apple.com/de/app/clique-the-rewards-app/id6741565671?l=en-GB"
                                target="_blank">
                                <div class="clickable-area app-store"></div>
                            </a>
                        </div>
                        <div class="image-container">
                            <img src=".././assets/frame_1707479811-1.webp" alt="clique"
                                class="m-banner d-flex d-lg-none">

                            <!-- Play Store Area -->
                            <a href="http://play.google.com/store/apps/details?id=com.getclique.app" target="_blank">
                                <div class="clickable-area play-store"></div>
                            </a>

                            <!-- App Store Area -->
                            <a href="http://apps.apple.com/de/app/clique-the-rewards-app/id6741565671?l=en-GB"
                                target="_blank">
                                <div class="clickable-area app-store"></div>
                            </a>
                        </div>
                    </div>

                    <div class="frame-section" *ngIf="currentCategory === 'Business'">
                        <div class="image-container2">
                            <img src=".././assets/frame_1707479811.webp" alt="clique" class="d-none d-lg-flex">

                            <!-- Play Store Area -->
                            <a href="http://play.google.com/store/apps/details?id=com.getclique.app" target="_blank">
                                <div class="clickable-area2 play-store2"></div>
                            </a>

                            <!-- App Store Area -->
                            <a href="http://apps.apple.com/de/app/clique-the-rewards-app/id6741565671?l=en-GB"
                                target="_blank">
                                <div class="clickable-area2 app-store2"></div>
                            </a>
                        </div>
                        <div class="image-container2">
                            <img src=".././assets/frame_1707479811.webp" alt="clique" class="m-banner d-flex d-lg-none">

                            <!-- Play Store Area -->
                            <a href="http://play.google.com/store/apps/details?id=com.getclique.app" target="_blank">
                                <div class="clickable-area2 play-store2"></div>
                            </a>

                            <!-- App Store Area -->
                            <a href="http://apps.apple.com/de/app/clique-the-rewards-app/id6741565671?l=en-GB"
                                target="_blank">
                                <div class="clickable-area2 app-store2"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="currentCategory == 'Business'">
        <app-business-page></app-business-page>
    </div>

    <div *ngIf="currentCategory == 'Customer'">
        <app-customer-page></app-customer-page>
    </div>